let baseClass = require('@/client/extensions/composition/asyncOperations/responseAdapters/interface.class.js');

import  responseObjectFactory  from '@/client/extensions/composition/asyncOperations/responseObjectFactory.js';

class SaffronAdapter extends baseClass.default {
    conf = config.asyncData.responseAdapters.platform || {};

    constructor (options) {
        super(options);
        return this;
    }

    isPlatformResponseStructure (arg) {
        return (typeof arg === 'object' &&
                arg.hasOwnProperty('data') &&
                arg.hasOwnProperty('status')
        )
    }
    parse (response, options) {
        let final = responseObjectFactory.getResponseObject();

        if (typeof options !== 'object' || options === null) {
            options = {};
        }



        if ( typeof response !==  'object' || response === null || typeof response.status === 'undefined') {
            final.isError = true;
            final.error.message = 'non-parseable response';
            final.error.info = response;
            final.code = 500;
            final.data = null;
            final.status.code = 500;
            final.status.message = 'non-parseable response';
            debug('Response adapter platformed received exceptional input. Check your request', 2, response);

            return final;
        }


        // this error is either from the request parser or the actual platform response
        // if this is not a platform response, pagination will be missing. add it
        if (typeof response.pagination !== 'object' || response.pagination === null) {
            response.pagination = {total: null, current: null, batchSize: null};
        }

        // now both an "actual" error and "intended" errors are valid for us to continue
        final.isError = (response.status.code < 199 || response.status.code > 300);
        final.code = response.status.code;
        final.status.code = response.status.code;
        final.status.message = response.status.message;
        final.data = response.data;

        // handle error structure
        if (final.isError) {
            final.error.message = response.status.message;
            final.error.info = 'todo: saffron response adapter - add error.info?';
            final.error.code = response.status.code;

            // check structure of the data property and modify it if needed
            if (this.isPlatformResponseStructure(response.data)) {
                final.data = response.data.data;
            }
        }

        if (typeof options.responseRaw === 'boolean' && options.responseRaw) {
            return response;
        }

        return final;
    }


}
export default (options) => {
    return new SaffronAdapter(options)
};
